@import "nlib/config";

.filters {
  margin-top: -$contentDefaultIndent * 1.5;
  padding-top: $contentDefaultIndent * 1.5;
  padding-bottom: $contentDefaultIndent * 1.5;
  background-color: $uiWhiteColor;
  .container {
    display: flex;
    height: 3rem;
    padding-right: $contentDefaultIndent * 2;
    padding-left: $contentDefaultIndent * 2;
    .title {
      display: flex;
      position: relative;
      flex: auto;
      align-items: center;
      margin-right: $contentDefaultIndent * 2;
      padding-right: 4rem;
      overflow: hidden;
      > span {
        display: block;
        flex: auto;
        overflow: hidden;
        font-size: 1.125rem;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
