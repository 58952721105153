@import "nlib/config";

.accountsPage {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: $mobileNavBarHeight;
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: $contentDefaultIndent 0 $contentDefaultIndent * 2;
  }
  .errorAlert {
    margin-top: $contentDefaultIndent;
  }
}
