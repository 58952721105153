@import "config";
@import "animations";

.title {
  display: flex;
  position: relative;
  position: sticky;
  z-index: 2;
  top: 0;
  align-items: center;
  height: 3.25rem;
  margin-right: -$contentDefaultIndent * 2;
  margin-bottom: $contentDefaultIndent;
  margin-left: -$contentDefaultIndent * 2;
  padding: 0 $contentDefaultIndent * 2;
  transition: box-shadow 0.2s;
  background-color: $uiBackgroundColor;
  box-shadow: 0 4px 10px rgba(26, 28, 32, 0);
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap;
  &:not(:first-child) {
    margin-top: $contentDefaultIndent;
  }
  &.shadow {
    box-shadow: 0 6px 10px rgba(26, 28, 32, 0.05);
  }
  &.withMargin {
    top: 4.5rem;
  }
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
    overflow: hidden;
    border-radius: $uiBorderRadius;
    img {
      max-width: 100%;
    }
  }
  .name {
    margin-right: $contentDefaultIndent * 2;
    overflow: hidden;
    font-size: 1rem;
    font-weight: 500;
    text-overflow: ellipsis;
  }
  .reconnectButton {
    width: 2.5rem !important;
    height: 2.5rem !important;
    margin-right: $contentDefaultIndent * 2;
    margin-left: $contentDefaultIndent;
    padding: 0;
    border: 1px solid transparent;
    line-height: 1;
    svg {
      color: $uiWhiteColor;
      font-size: 1rem;
    }
  }
  .expiresAt {
    display: flex;
    align-items: center;
    margin-left: auto;
    font-size: 0.75rem;
    .successIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.5rem;
      height: 2.5rem !important;
      color: $uiPositiveColor;
      svg {
        font-size: 1.5rem;
      }
    }
    .text {
      color: $uiNeutralColor;
      &.warning {
        color: $uiWarningColor;
      }
      &.expired {
        color: $uiNegativeColor;
        text-transform: uppercase;
      }
    }
  }
  .action {
    margin-right: $contentDefaultIndent;
    margin-left: $contentDefaultIndent * 2;
  }
}
.list {
  .account {
    width: 100%;
    margin: 0 auto $contentDefaultIndent;
    padding: $contentDefaultIndent * 2 $contentDefaultIndent * 2 $contentDefaultIndent;
    border-radius: $uiBorderRadius;
    background-color: $uiWhiteColor;
    box-shadow: 0 1px 4px rgba(70, 70, 70, 0.1);
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $contentDefaultIndent * 2;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.25rem;
      white-space: nowrap;
      .name {
        margin-right: $contentDefaultIndent * 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .balance {
        font-weight: bold;
      }
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: $contentDefaultIndent $contentDefaultIndent * 3;
      .item {
        margin-bottom: $contentDefaultIndent;
        .label {
          height: 1rem;
          font-size: 0.6875rem;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
      .deleteButton {
        width: 2.5rem !important;
        height: 2.5rem !important;
        margin-left: auto;
      }
    }
  }
  [data-wait] {
    animation: rotate 2s linear infinite;
  }
}
