@import "config";

.connectInstitutionWindow {
  .dialogClassName {
    width: 40rem;
    .contentClassName {
      padding-right: $contentDefaultIndent * 1.5 !important;
      padding-left: $contentDefaultIndent * 1.5 !important;
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 100%;
      padding: $contentDefaultIndent / 2;
      white-space: nowrap;
      .content {
        display: flex;
        align-items: center;
        padding: $contentDefaultIndent;
        border-radius: $uiBorderRadius;
        cursor: pointer;
        &:hover {
          background-color: darken($uiBackgroundColor, 5%);
        }
        &:active {
          background-color: darken($uiBackgroundColor, 3%);
        }
        &.connected {
          background-color: darken($uiBackgroundColor, 2%);
          svg {
            display: block;
          }
        }
      }
      .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5rem;
        min-width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.5rem;
        overflow: hidden;
        border-radius: $uiBorderRadius;
        img {
          max-width: 100%;
        }
      }
      .name {
        flex: auto;
        overflow: hidden;
        font-weight: 500;
        text-overflow: ellipsis;
      }
      svg {
        display: none;
        color: $uiPositiveColor;
        font-size: 1rem;
      }
    }
  }
}

@media (min-width: 420px) {
  .connectInstitutionWindow {
    .list {
      .item {
        width: 50%;
      }
    }
  }
}

@media (min-width: 620px) {
  .connectInstitutionWindow {
    .list {
      .item {
        width: 33.3333%;
      }
    }
  }
}
